<template>
  <div>
    <site-add></site-add>
  </div>
</template>

<script>
import SiteAdd from './SiteAdd.vue';

export default {
  components: {
    SiteAdd,
  },

  data() {
    return {};
  },
};
</script>
